import Store from '../Store/Store';
import { content } from '../Data/content'

const CheckoutWidget = props => {

    const domain = Store.get( 'domain' )
    if ( !domain ) return
	const c = content[ domain ]
	const bookNowUrl = c.bookUrl + '&activityId=' + props.activityId + '&lang=' + c.lang + '&ccy=' + c.currency + '&hash=' + c.bookHash

	//let bookNowUrl = "https://widgets.bokun.io/widgets/58055?bookingChannelUUID=01d86bf1-36f9-461b-8253-de724cafa93d&activityId=" + props.activityId + "&lang=en&ccy=EUR&hash=w58055_e3dd74d3_289e_4fa4_b266_58021497ec36"
	//let bookNowUrl = "https://widgets.bokun.io/widgets/53214?bookingChannelUUID=1b710447-b6bb-468b-8502-8dcfab9f5c17&activityId=" + props.activityId + "&lang=en&ccy=" + currency + "&hash=w53214_6ab1712f_bb7b_4fd5_8364_1f329c6c3af9"
	//let bookNowUrl = "https://widgets.bokun.io/widgets/53214?bookingChannelUUID=1b710447-b6bb-468b-8502-8dcfab9f5c17&activityId=" + props.activityId + "&lang=en&ccy=" + 'USD' + "&hash=w53214_6ab1712f_bb7b_4fd5_8364_1f329c6c3af9"


	return (
    	<div id="checkout" className="products-checkout" >
    		<h2 style={{ padding:'0 0 20px 0', margin:0, fontSize:'24px', textAlign:'center' }} >Book Now</h2>
			<iframe 
				width="100%" 
				height="1800" 
				frameBorder="0"
				scrolling="no"
				title="Checkout"
				style={{ marginBottom:'20px', boxSizing:'border-box' }}
				src={ bookNowUrl }
			>
			</iframe>
    	</div>
	)
}

export default CheckoutWidget
